




















































































import Vue from "vue";
import { mapState, mapMutations } from "vuex";

export default Vue.extend({
  name: "DashboardCoreAppBar",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      notifications: [],
      profile: [
        { title: "Profile" },
        { title: "Settings" },
        { divider: true },
        { title: "Log Out" },
      ],
    };
  },
  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    profileAction(title: string) {
      switch (title) {
        case "Log Out":
          this.logout();
          break;
      }
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
});
